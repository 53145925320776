import { RESERVAS_ACTIONS_OPEN_COMMENT } from '../reservasActions/type';
import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { notifications } from "../../../helpers/notifications";
import {
    getReservaDetalleBy,
    postGenerarCartaReserva,
    postNegarReservas,
    postProcesarReserva,
    putReservaDetalle
} from '../../../helpers/reservasServices';
import { Action } from "../../../interfaces/general";
import { reservasDetallesClear, setReservasDetalles } from './actions';
import {
    RESERVAS_DETALLES_CHANGE_ESTADO_SET,
    RESERVAS_DETALLES_CONFIRM_PROCESS,
    RESERVAS_DETALLES_DENY_PROCESS,
    RESERVAS_DETALLES_GET,
    RESERVAS_DETALLES_LETTER_PROCESS,
    RESERVAS_DETALLES_SET_COMENTARIO,
    RESERVAS_DETALLES_UPDATE_COMENTARIO,
    RESERVAS_DETALLES_MULTI_LETTER_PROCESS,
    RESERVAS_DETALLES_CLEAR,
    RESERVAS_DETALLES_LOADING,
    RESERVAS_DETALLES_CONFIRM_ALL
} from './types';
import { RootState } from '../../index';
import {
    RESERVAS_ACTIONS_OPEN_CONFIRM,
    RESERVAS_ACTIONS_OPEN_DENY,
    RESERVAS_ACTIONS_OPEN_LETTER,
    RESERVAS_ACTIONS_IS_ALL_ACTIVE,
    RESERVAS_ACTIONS_OPEN_MULTI_LETTER,
    RESERVAS_ACTIONS_OPEN_CONFIRM_ALL,
    RESERVAS_ACTIONS_PROCESSING_LETTER
} from '../reservasActions/type';
import { ReservaDetallesChangeSend } from '../../../interfaces/sends';
import { ReservaDetalle } from '../../../interfaces/reservasDetalle';
import { RESERVAS_OPERACIONES_GET_BY_ESTADO } from "../reservas/types";

function* processGetReservasDetalles({ payload }: Action<number>): any {
    const { ReservasOperaciones, Catalogos, ReservasActions: { currentPathEstado } } = yield select((state: RootState) => state);
    const { selected } = ReservasOperaciones;
    const { estados } = Catalogos;
    const { name: estadoPath } = currentPathEstado;
    try {
        yield put({ type: RESERVAS_DETALLES_LOADING, payload: true });
        const data: ReservaDetalle[] = yield call(getReservaDetalleBy, payload, selected.EstadoId, estados['Reserva'], estadoPath);
        yield put(reservasDetallesClear());
        yield put(setReservasDetalles(data));
        const changeDetalle: ReservaDetallesChangeSend[] = [];
        data.forEach(rd => {
            changeDetalle.push({
                reservaDetalleId: rd.ReservaDetalleId,
                estadoActualizadoId: rd.EstadoId,
                reservaId: rd.ReservaId
            });
        });
        yield put({ type: RESERVAS_DETALLES_CHANGE_ESTADO_SET, payload: changeDetalle });
    } catch (error) {
        yield put(setReservasDetalles([]));
        notifications({ ex: error })
    } finally {
        yield put({ type: RESERVAS_DETALLES_LOADING, payload: false });
    }
}

function* denyProcess(): any {
    const { ReservasOperaciones, ReservasActions } = yield select((state: RootState) => state);
    const { selected } = ReservasOperaciones;
    const { currentPathEstado } = ReservasActions;
    try {
        yield call(postNegarReservas, selected.ReservaId);
        yield put({ type: RESERVAS_OPERACIONES_GET_BY_ESTADO, payload: currentPathEstado.estadosId });
        yield put(reservasDetallesClear());
        yield put({ type: RESERVAS_ACTIONS_OPEN_DENY, payload: false });
        notifications({ title: 'Exito', message: 'Negado', toastType: 'success' });
    } catch (error) {
        yield put({ type: RESERVAS_ACTIONS_OPEN_DENY, payload: false });
        notifications({ ex: error });
    }
}

function* letterProcess({ payload }: Action<boolean>) {
    const { ReservasOperaciones, ReservasDetalles, ReservasActions } = yield select((state: RootState) => state);
    const { selected } = ReservasOperaciones;
    const { changeDetalle } = ReservasDetalles;
    const { currentPathEstado, tipo } = ReservasActions;
    try {
        yield put({ type: RESERVAS_ACTIONS_PROCESSING_LETTER, payload: true });
        yield call(postGenerarCartaReserva, changeDetalle, tipo, payload, selected.ReservaId);
        yield put(reservasDetallesClear());
        yield put({ type: RESERVAS_OPERACIONES_GET_BY_ESTADO, payload: currentPathEstado.estadosId });
        notifications({ title: 'Exito', message: `Carta Generada`, toastType: 'success' });
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put({ type: RESERVAS_ACTIONS_PROCESSING_LETTER, payload: false });
        yield put({ type: RESERVAS_ACTIONS_OPEN_LETTER, payload: false });
    }
}


function* confirmProcess() {
    const { ReservasOperaciones, ReservasDetalles, ReservasActions } = yield select((state: RootState) => state);
    const { selected } = ReservasOperaciones;
    const { changeDetalle } = ReservasDetalles;
    const { currentPathEstado, tipo } = ReservasActions;
    try {
        yield call(postProcesarReserva, changeDetalle, tipo, selected.ReservaId);
        yield put(reservasDetallesClear());
        yield put({ type: RESERVAS_OPERACIONES_GET_BY_ESTADO, payload: currentPathEstado.estadosId });
        notifications({ title: 'Exito', message: `Confirmado`, toastType: 'success' });
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put({ type: RESERVAS_ACTIONS_OPEN_CONFIRM, payload: false });
    }
}

function* modificarComentario({ payload }: Action<string>) {
    const { selected } = yield select((state: RootState) => state.ReservasDetalles);
    const sendData = { ReservaDetalleId: selected.ReservaDetalleId, Comentario: payload };
    try {
        yield call(putReservaDetalle, selected, payload);
        yield put({ type: RESERVAS_DETALLES_UPDATE_COMENTARIO, payload: sendData });
        notifications({ title: 'Exito', message: `Se guardo el comentario`, toastType: 'success' });
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put({ type: RESERVAS_ACTIONS_OPEN_COMMENT, payload: false });
    }
}

function* multiLetterProcess({ payload }: Action<boolean>) {
    const { ReservasActions, ReservasDetalles, Catalogos } = yield select((state: RootState) => state);
    const { changeDetalle } = ReservasDetalles;
    const { currentPathEstado, tipo } = ReservasActions;
    const { estados } = Catalogos;
    try {
        const newChangeSoloSolicitados = changeDetalle.filter((rd: any) => (
            rd.estadoActualizadoId === estados['Reserva']['Reserva Solicitada'].EstadoId
            || rd.estadoActualizadoId === estados['Reserva']['Modificación Solicitada'].EstadoId));
        yield call(postGenerarCartaReserva, newChangeSoloSolicitados, tipo, payload);
        yield put({ type: RESERVAS_DETALLES_CLEAR });
        yield put({ type: RESERVAS_OPERACIONES_GET_BY_ESTADO, payload: currentPathEstado.estadosId });
        notifications({ title: 'Exito', message: `Se generó la carta`, toastType: 'success' });
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put({ type: RESERVAS_ACTIONS_IS_ALL_ACTIVE, payload: false });
        yield put({ type: RESERVAS_ACTIONS_OPEN_MULTI_LETTER, payload: false });
    }
}

function* confirmAll() {
    try {
        const { ReservasDetalles, ReservasActions, Catalogos } = yield select((state: RootState) => state);
        const { estados } = Catalogos;
        const { changeDetalle } = ReservasDetalles;
        const { currentPathEstado, tipo } = ReservasActions;
        yield put({ type: RESERVAS_ACTIONS_PROCESSING_LETTER, payload: true });
        let reservasAconfirmar: number[] = [];
        changeDetalle.forEach((rd: ReservaDetallesChangeSend) => {
            if (rd.cartaEnviada &&
                (rd.estadoActualizadoId === estados['Reserva']['Reserva Solicitada'].EstadoId
                    || rd.estadoActualizadoId === estados['Reserva']['Modificación Solicitada'].EstadoId
                    || rd.estadoActualizadoId === estados['Reserva']['Negado'].EstadoId)) {
                reservasAconfirmar.push(rd.reservaId);
            }
        });
        yield call(postProcesarReserva, changeDetalle, tipo, reservasAconfirmar);
        yield put({ type: RESERVAS_OPERACIONES_GET_BY_ESTADO, payload: currentPathEstado.estadosId });
        notifications({ title: 'Exito', message: `Confirmado`, toastType: 'success' });
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put({ type: RESERVAS_ACTIONS_OPEN_CONFIRM_ALL, payload: false });
        yield put({ type: RESERVAS_ACTIONS_PROCESSING_LETTER, payload: false });
    }
}

// WATCHERS

function* watchConfirmAll() {
    yield takeLatest(RESERVAS_DETALLES_CONFIRM_ALL, confirmAll);
}

function* watchMultiLetterProcess() {
    yield takeLatest(RESERVAS_DETALLES_MULTI_LETTER_PROCESS, multiLetterProcess);
}

function* watchComment() {
    yield takeLatest(RESERVAS_DETALLES_SET_COMENTARIO, modificarComentario);
}

function* watchConfirmProcess() {
    yield takeEvery(RESERVAS_DETALLES_CONFIRM_PROCESS, confirmProcess);
}

function* watchLetterProcess() {
    yield takeEvery(RESERVAS_DETALLES_LETTER_PROCESS, letterProcess);
}

function* watchDenyProcess() {
    yield takeEvery(RESERVAS_DETALLES_DENY_PROCESS, denyProcess);
}

function* watchGetReservasDetalles() {
    yield takeEvery(RESERVAS_DETALLES_GET, processGetReservasDetalles);
}


export default function* reservasDetallesSagas() {
    yield all([
        fork(watchGetReservasDetalles),
        fork(watchDenyProcess),
        fork(watchLetterProcess),
        fork(watchConfirmProcess),
        fork(watchComment),
        fork(watchMultiLetterProcess),
        fork(watchConfirmAll),
    ]);
}