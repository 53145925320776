import { Action } from "../../../interfaces/general"
import {
    RESERVAS_OPERACIONES_ABRIR_LISTA_PASAJEROS,
    RESERVAS_OPERACIONES_CLEAR, RESERVAS_OPERACIONES_GET_BY_ESTADO,
    RESERVAS_OPERACIONES_LOADING,
    RESERVAS_OPERACIONES_RESERVAS_ID_ALL,
    RESERVAS_OPERACIONES_SELECTED_CLEAR,
    RESERVAS_OPERACIONES_SELECTED_DELETE,
    RESERVAS_OPERACIONES_SELECTED_SET,
    RESERVAS_OPERACIONES_SET_DATA, RESERVAS_OPERACIONES_SET_FILTRO_RESERVADO,
    RESERVAS_OPERACIONES_SET_SUCESORES,
    RESERVAS_OPERACIONES_UPDATE_DATA,
    RESERVAS_OPERACIONES_UPDATE_TOTALES,
    RESER_OPS_UPDATE_TRESERVAS_BLOQUEADAS
} from './types';
import { Reserva } from '../../../interfaces/reserva';
import { SucesorValido } from '../../../interfaces/respuestas';
import moment from "moment/moment";

export interface StateReservasOperaciones {
    abrirListaPasajeros: boolean;
    cancelaciones: number;
    data: Reserva[];
    estadoId: number[];
    filtroReservado: FiltroReservado;
    loading: boolean;
    pendientes: number;
    reservasBloqueadas: number;
    reservasId: number[];
    selected: Reserva;
    sucesorValido: SucesorValido[];
    [key: string]: boolean | number | number[] | Reserva[] | Reserva | SucesorValido[] | FiltroReservado;
}

export interface FiltroReservado {
    fechaInicio: Date;
    fechaFin: Date;
}

const INITIAL_STATE: StateReservasOperaciones = {
    abrirListaPasajeros: false,
    cancelaciones: 0,
    data: [],
    estadoId: [],
    loading: false,
    pendientes: 0,
    reservasBloqueadas: 0,
    reservasId: [],
    selected: {} as Reserva,
    sucesorValido: [],
    filtroReservado: {
        fechaInicio: moment().subtract(1, 'M').startOf('month').toDate(),
        fechaFin: moment().add(2, 'M').endOf('month').toDate()
    }
}

const actions: Action<boolean | Reserva[] | SucesorValido[] | Reserva | number | any> = {
    type: '',
    payload: false
}

const ReservasOperaciones = (state = INITIAL_STATE, { type, payload } = actions) => {
    switch (type) {
        case RESERVAS_OPERACIONES_LOADING:
            return {
                ...state,
                loading: payload
            }
        case RESERVAS_OPERACIONES_SET_DATA:
            return {
                ...state,
                data: payload
            }
        case RESERVAS_OPERACIONES_CLEAR:
            return {
                ...state,
                data: [],
                selected: {}
            }
        case RESERVAS_OPERACIONES_UPDATE_TOTALES:
            const { pendientes, cp } = payload;
            const rp = pendientes !== undefined ? pendientes : state.pendientes;
            const rcp = cp !== undefined ? cp : state.cancelaciones;
            return {
                ...state,
                pendientes: rp,
                cancelaciones: rcp,
            }
        case RESERVAS_OPERACIONES_SET_SUCESORES:
            return {
                ...state,
                sucesorValido: payload
            }
        case RESERVAS_OPERACIONES_SELECTED_SET:
            return {
                ...state,
                selected: payload
            }
        case RESERVAS_OPERACIONES_SELECTED_DELETE:
            return {
                ...state,
                data: state.data.filter(r => r.ReservaId !== payload)
            }
        case RESERVAS_OPERACIONES_UPDATE_DATA:
            return {
                ...state,
                data: state.data.map(item => item.ReservaId === payload.ReservaId
                    ? payload
                    : item)
            }
        case RESERVAS_OPERACIONES_SELECTED_CLEAR:
            return {
                ...state,
                selected: {}
            }
        case RESERVAS_OPERACIONES_RESERVAS_ID_ALL:
            return {
                ...state,
                reservasId: payload,
                selected: {} as Reserva
            }
        case RESERVAS_OPERACIONES_ABRIR_LISTA_PASAJEROS:
            return {
                ...state,
                abrirListaPasajeros: payload,
            }
        case RESERVAS_OPERACIONES_GET_BY_ESTADO:
            return {
                ...state,
                estadoId: payload,
            }
        case RESERVAS_OPERACIONES_SET_FILTRO_RESERVADO:
            return {
                ...state,
                filtroReservado: payload,
            }
        case RESER_OPS_UPDATE_TRESERVAS_BLOQUEADAS:
            return {
                ...state,
                reservasBloqueadas: payload
            }
        default:
            return state;
    }
}


export default ReservasOperaciones;
